import * as React from "react"
import loadable from "@loadable/component"
import pageContent from "../../content/about.json"

const Layout = loadable(() => import("@common/Layout/Layout"))
const ContactUs = loadable(() => import("@components/ContactUs/ContactUs"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const ContactLayout = props => {
  const parentProps = props.parentProps
  const content = pageContent.content[parentProps.pageContext.langKey]

  return (
    <>
      <SEO title={content.seo.title} description={content.seo.description} />
      <Layout
        location={parentProps.location}
        lang={parentProps.pageContext.langKey}
      >
        <ContactUs />
      </Layout>
    </>
  )
}

export default ContactLayout
